import React from "react"
import { GatsbyLocation } from "local-types"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import { useSiteMetadata } from "../hooks"

import FooterContact from "../components/ContactComponents/FooterContact"
import ScheduleDemo from "../components/LandingPage/ScheduleDemo"
import SEO from "../components/Seo/Seo"
import FlipbaseHero from "../components/FlipbaseHero"
import ProductsRow from "../components/LandingPage/LandingPageContent/ProductRow"
import ExplainerVideo from "../components/ExplainerVideo"
import TestimonialRow from "../components/TestimonialRow"
import BlogIndexRow from "../components/BlogIndexRow"

interface NewIndex {
  location: GatsbyLocation
  data: object
}

const heroNewIndex = {
  title: "Flipbase",
  subhead: "Integrated asynchronous video communication for recruitment professionals",
  backgroundImageHero: "/Flipbase_office.jpeg",
  description: "Boost your recruitment process by adding video! Let us show you more, by clicking on the button below.",
  logoHero:"/logo-hero.png",
  linkToAnchor:"/newIndex#products-index",
}

const ProductsIndex = {
    ProductsTitle:"Flipbase products",
    ProductsInfo:[
      {
        title:"Candidate Screening",
        description:"Independent Candidate Screening solution, with e-mail notifications.",
      },
      {
        title:"Integrations",
        description:"Integrated Candidate Screening solution. Get our product integrated into your ATS.",
      },
      {
        title:"Whitelabel",
        description:"Whitelabel Partnership with any ATS.",
      },
    ]
}

const TestimonialSectionData = {
  TestimonialTitle:"Why other companies love Flipbase",
  TestimonialData: [
    {
      testimonialText:"Flipbase helps us finding the perfect candidate",
      personImage:"/14m.jpg",
      personName:"First Last",
      personPosition:"Recruiter at Company X"
    },
    {
      testimonialText:"The Flipbase technology helps us save a lot of time",
      personImage:"/10m.jpg",
      personName:"First Last",
      personPosition:"HR Business Partner at Company X"
    },
    {
      testimonialText:"We can now find the right candidate with limited recourses, using Flipbase.",
      personImage:"/09w.jpg",
      personName:"First Last",
      personPosition:"Recruiter at Company X"
    }
  ]
}

const ExplainerVideoData = {
  title: "Explainer video",
  description: "Our technology explained in video",
  embedVideo: "https://www.youtube.com/embed/jzPKFcMjXME", 
}

const newIndexMetadata = {
  Title: "What is Flipbase? Integrated video technology",
  Keywords: ["Flipbase", "What is Flipbase?", "What is Flipbase"],
  Description:
    "What is Flipbase? We are an integrated video technology, specialized in integrating asynchronous video communication for recruitment professionals.",
}

const NewIndexPage: React.FunctionComponent<NewIndex> = ({
  location,
  data,
}): React.ReactElement => {
  const blogCards = data.allMdx.edges
  const { title: siteTitle } = useSiteMetadata()
  return (
    <Layout
      location={location}
      title={siteTitle}
    >
      <SEO
        title={newIndexMetadata.Title}
        description={newIndexMetadata.Description}
        keywords={newIndexMetadata.Keywords}
      />
        <FlipbaseHero
          backgroundImageHero={heroNewIndex.backgroundImageHero}
          title={heroNewIndex.title}
          subhead={heroNewIndex.subhead}
          linkToAnchor={heroNewIndex.linkToAnchor}
          description={heroNewIndex.description}
          imageHero={heroNewIndex.logoHero}
          scheduleButton={true}
        />
        <ProductsRow 
          id="products-index"
          data={ProductsIndex.ProductsInfo} 
          title={ProductsIndex.ProductsTitle}
        />
        <ExplainerVideo 
          title={ExplainerVideoData.title}
          descritption={ExplainerVideoData.description}
          embedVideo={ExplainerVideoData.embedVideo}
        />
        <TestimonialRow data={TestimonialSectionData} />
        <BlogIndexRow 
          data={blogCards}
          title="Check out our recent <span>Flipbase</span> articles"
          descritption="Want to know more about Recruitment and Flipbase? Read our customer stories and recruitment-related blogs."
        />
        <ScheduleDemo />
        <FooterContact paddingLaptop="20px 120px 20px 120px;" elPosition="relative"  />
    </Layout>
  )
}


export default NewIndexPage


export const pageQuery = graphql`
  query blogIndexPaige {
    allMdx(
      limit:3
      sort: { order: DESC, fields: frontmatter___created }
    ) {
      edges {
        node {
          id
          excerpt(pruneLength: 120)
          frontmatter {
            title
            description
            featuredImage {
              relativePath
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          fields {
            slug
          }
        }
      }
    }
  }
`
