import React from "react"
import styled from "styled-components"
import { Col, Row } from "antd"
import FlipbaseCard from "../FlipbaseCard"

import { device,colors } from "../../styles/constants"

interface TestimonialRowProps {
  author: SiteSiteMetadataAuthor
  data: Record<string, any>
}

const TestimonialRow: React.FunctionComponent<TestimonialRowProps> = ({
  data,
}): React.ReactElement => {
  
  return (
    <Layout>
      <TitleTestimonial> {data.TestimonialTitle} </TitleTestimonial>
      <TestimonialsWrapper gutter={36}>
        {data.TestimonialData.map((testimonial) => {
            
            return (
                <StyledCol xl={8} lg={8} md={24} sm={24} xs={24}>
                    <h1>{testimonial.text}</h1>
                    <FlipbaseCard 
                        type="Testimonial"
                        backgroundHeaderColor="rgb(245, 218, 230)"
                        data={testimonial}
                    />
                </StyledCol>
            )
        })}
      </TestimonialsWrapper>
    </Layout>
  )
}



const Layout = styled.div`
  max-width:1200px;
  margin:0 auto;
  text-align:center;
  padding-top: 30px;
  padding-bottom: 60px;
`

const TitleTestimonial  = styled.h3`
  font-size: 36px;
  line-height: 1.23;
  font-family: FreightSans;
  color:#000;
  opacity:0.8;
  font-weight:600;
`

const TestimonialsWrapper = styled(Row)`
    max-width:1200px !important;
`

const StyledCol = styled(Col)``

export default TestimonialRow