import React from "react"
import { Col, Row } from "antd"
import styled from "styled-components"

interface ProductsRowProps {

  data: any[]
}

const ProductsRow: React.FunctionComponent<ProductsRowProps> = ({
  data,

}): React.ReactElement => {
  
  return (
    <>

      <WhatIsFlipabseWrapper>
        <TextWrapper>
          <WhatIsFlipabseTitle>
            {" "}
            So, what is Flipbase exactly?
          </WhatIsFlipabseTitle>
          <WhatIsFlipabseDescription>
            We are a software company, and we offer integrated asynchronous
            video communication for recruitment professionals. Flipbase is a
            collective name for these three products:
          </WhatIsFlipabseDescription>
        </TextWrapper>
        <ProductsWrapper>
          <Row>
            {data.map(product => {
              return (
                <StyledCol xl={8} lg={8} md={24} sm={24} xs={24}>
                  <InfoWrapper>
                    <TitleProduct>{product.title}</TitleProduct>
                    <DescriptionProduct>
                      {product.description}
                    </DescriptionProduct>
                  </InfoWrapper>
                </StyledCol>
              )
            })}
          </Row>
        </ProductsWrapper>
      </WhatIsFlipabseWrapper>

    </>
  )
}

const WhatIsFlipabseWrapper = styled.div`
  padding-top: 100px;
  padding-bottom: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
`

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 105px;
`

const WhatIsFlipabseTitle = styled.h3`
  font-family: FreightSans;
  margin-bottom: 40px;
  font-size: 42px;
  line-height: 1.23;
  font-weight: 600;
  color: #000;
  opacity: 0.8;
`

const WhatIsFlipabseDescription = styled.p`
  font-family: FreightSans;
  font-size: 20px;
  max-width: 500px;
  text-align: center;
`

const ProductsWrapper = styled.div`
  max-width: 1200px;
  margin: 0 auto;
`

const StyledCol = styled(Col)`
  text-align: center;
  padding: 0 25px;
  :nth-of-type(2) {
    border-left: 1.6px solid #d1d1d1;
    border-right: 1.6px solid #d1d1d1;
  }
`

const InfoWrapper = styled.div`
  margin: 0 24px;
`

const TitleProduct = styled.h5`
  font-family: FreightSans;
  font-size: 24px;
  padding-bottom: 20px;
  font-weight: 600;
  color: #000;
  opacity: 0.8;
  line-height: 1.35;
`

const DescriptionProduct = styled.span`
  font-size: 18px;
  line-height: 1.55;
  font-weight: 300;
  color: #000;
  opacity: 0.8;
  line-height: 1.55;
`


export default ProductsRow

