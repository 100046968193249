import React from "react"
import styled from "styled-components"


interface ExplainerVideoProps {
  embedVideo: string,
  title: string,
  descritption: string,
}

const ExplainerVideo: React.FunctionComponent<ExplainerVideoProps> = ({
  embedVideo,
  title,
  descritption
}): React.ReactElement => {
  
  return (
    <WrapperExplainer>
        {title && (
          <TitleExplainer>{title}</TitleExplainer>
        )}
        { descritption && (
          <DescritptionExplainer>{descritption}</DescritptionExplainer>
        )}  
        {embedVideo && (
            <WrapperIframe>
                <ContainerIframe>
                    <StyledIframe className="responsive-iframe" src={embedVideo} allowFullScreen></StyledIframe>
                </ContainerIframe>
            </WrapperIframe>
        )}
    </WrapperExplainer>
  )
}




export default ExplainerVideo


const WrapperExplainer = styled.div`
  text-align:center;
`

const TitleExplainer = styled.h4`
  font-family: FreightSans;
  margin-bottom: 24px;
  font-size: 42px;
  line-height: 1.23;
  font-weight: 600;
  color: #000;
  opacity: 0.8;
`

const DescritptionExplainer = styled.p`
  font-family: FreightSans;
  font-size: 32px;
  max-width: 500px;
  text-align: center;
  color: #000;
  opacity: 0.8;
  margin:0 auto 32px;
`

const ContainerIframe = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%;

  .responsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  }
`

const StyledIframe = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none !important;
  background-color: none !important;
`

const WrapperIframe = styled.div`
  max-width:800px;
  margin:0 auto 128px !important;
`






