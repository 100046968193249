import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import ModalHeader from "../../Layout/ModalHeader"

import { SpringFadeUp, TrailFadeUp } from "../../../utils/animation"

interface ScheduleDemoProps {}

const ScheduleDemo: React.FunctionComponent<
  ScheduleDemoProps
> = ({}): React.ReactElement => {
  return (
    <ScheduleWrapper>
      <IntroSchedule>
        If you would like more information about "What is Flipbase", click{" "}
        <Link to="https://www.flipbase.com/introduction">here</Link>
        for our Candidate Screening presentation.
      </IntroSchedule>
      <ScheduleBody>
        <TrailFadeUp>
          <ScheduleTitle>Schedule a demo!</ScheduleTitle>

          <ScheduleDescription>
            Did we get you curious? Then schedule a demo right away to see what
            we can do for you or click here to start right away!
          </ScheduleDescription>
          <ButtonWrapper>
            <ModalHeader callToAction="Schedule Demo" />
          </ButtonWrapper>
        </TrailFadeUp>
      </ScheduleBody>
    </ScheduleWrapper>
  )
}

const ScheduleWrapper = styled.div`
  position: relative;
  width: 100%;
  max-width: 800px;
  text-align: center;
  margin: 32px auto;
`

const IntroSchedule = styled.h2`
  font-size: 20px;
  line-height: 1.55;
  font-family: FreightSans;
  font-weight: 300;
  color: #000;
  opacity: 0.8;
`
const ScheduleBody = styled.div`
  padding: 96px 0;
`

const ScheduleTitle = styled.h3`
  font-size: 48px;
  line-height: 1.23;
  font-family: FreightSans;
  font-weight: 600;
  color: #000;
  opacity: 0.8;
`

const ScheduleDescription = styled.p`
  font-size: 24px;
  line-height: 1.5;
  font-family: FreightSans;
  font-weight: 300;
  color: #000;
  opacity: 0.8;
  margin: 0 auto 48px;
  max-width: 500px;
`

const ButtonWrapper = styled.div``

export default ScheduleDemo
