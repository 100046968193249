import React from "react"
import styled from "styled-components"
import { Col, Row } from "antd"
import FlipbaseCard from "../FlipbaseCard"
import { device, colors } from "../../styles/constants"
import { Link } from "gatsby"

interface BlogIndexRowProps {
  author: SiteSiteMetadataAuthor
  data: Record<string, any>
  title: string
  descritption: string
}

const BlogIndexRow: React.FunctionComponent<BlogIndexRowProps> = ({
  data,
  title,
  descritption
}): React.ReactElement => {
  console.log(data)
  return (
    <Layout>
      <TitleBlogIndex dangerouslySetInnerHTML={{ __html: title }} /> 
      <DescritptionBlogIndex> {descritption} </DescritptionBlogIndex>
      <BlogIndexWrapper gutter={36}>
        {data.map((card) => {
            console.log(card)
            return (
                <StyledCol xl={8} lg={8} md={24} sm={24} xs={24}>
                    <Link to={card.node.fields.slug}>
                      <FlipbaseCard 
                          type="Image"
                          data={card}
                          alignContent="left"
                      />
                    </Link>
                </StyledCol>
            )
        })}
      </BlogIndexWrapper>
    </Layout>
  )
}



const Layout = styled.div`
  max-width:1200px;
  margin:0 auto;
  text-align:center;
  padding-top: 30px;
  padding-bottom: 60px;
  display:flex;
  flex-direction:column;
  align-items:center;
`

const TitleBlogIndex  = styled.p`
  font-size: 42px;
  line-height: 1.23;
  font-family: FreightSans;
  color:rgba(0, 0, 0, 0.8);
  font-weight:600;
  text-shadow: none !important;
  margin-bottom:0 !important;
  span {
    color:${colors.mainColor};
    font-family: FreightSans;
    text-shadow: none !important;
  }
`

const BlogIndexWrapper = styled(Row)`
  max-width:1200px !important;
  padding:32px 0;
`

const DescritptionBlogIndex = styled.span`
  font-size: 22px;
  max-width: 560px;
  display:block;
  margin:16px auto;
  color:rgba(0, 0, 0, 0.8);
`

const StyledCol = styled(Col)``

export default BlogIndexRow


/* {data.map((card) => {
            console.log(testimonial)
            return (
                <StyledCol xl={8} lg={8} md={24} sm={24} xs={24}>
                    <h1>{testimonial.text}</h1>
                    <FlipbaseCard 
                        type="Image"
                        data={testimonial}
                    />
                </StyledCol>
            )
        })}

*/