import React from "react"
import styled from "styled-components"
import { SiteSiteMetadataAuthor } from "graphql-types"
import { colors, device } from "../../../styles/constants"

import IconsContact from "../../DecorationElements/IconsContact"

const dataFooterContact = [
  {
    title: "Our office",
    links: [
      {
        title: "Plantage Kerklaan 35 H 1018 CV Amsterdam The Netherlands",
        link:
          "https://www.google.com/maps/place/Plantage+Kerklaan+35,+1018+CV+Amsterdam,+Nederland/@52.3668601,4.9097685,17z/data=!3m1!4b1!4m5!3m4!1s0x47c609a265f19a19:0x7980d8fa2e8bda41!8m2!3d52.3668601!4d4.9119572",
      },
    ],
  },
  {
    title: "Contact",
    information: "+31 20 893 26 47",
    links: [
      {
        title: "info@flipbase.com",
        link: "mailto:info@flipase.com",
      },
    ],
  },
  {
    title: "Docs",
    links: [
      {
        title: "API",
        link: "https://docs.flipbase.com/",
      },
      {
        title: "Terms & Conditions",
        link: "/terms-of-use-flipbase",
      },
    ],
  },
  {
    title: "Follow us",
    socialIcons: [
      {
        icon: "/fb_contact.svg",
        colorIcon: "/fb_color_contact.svg",
        linkIcon: "https://www.facebook.com/atflipbase",
      },
      {
        icon: "/ln_contact.svg",
        colorIcon: "/ln_color_contact.svg",
        linkIcon: "https://www.linkedin.com/company/flipbase",
      },
      {
        icon: "/in_contact.svg",
        colorIcon: "/in_color_contact.svg",
        linkIcon: "https://www.instagram.com/atflipbase",
      },
    ],
  },
]

function LinksFooter(props) {
  return (
    <>
      {props.data.map(linkItem => {
        return (
          <StyledLink target="blank" href={linkItem.link}>
            {linkItem.title}
          </StyledLink>
        )
      })}
    </>
  )
}

interface FooterContactProps {
  author: SiteSiteMetadataAuthor
  elPosition: string
  paddingLaptop: string
}

const FooterContact: React.FunctionComponent<FooterContactProps> = ({
  elPosition,
  paddingLaptop,
}): React.ReactElement => {
  return (
    <FooterWrapper elPosition={elPosition} paddingLaptop={paddingLaptop}>
      {dataFooterContact.map(item => {
        const itemInformation =
          item.information === "+31 20 893 26 47" ? (
            <StyledLink href="tel:0031208932647">{item.information}</StyledLink>
          ) : (
            item.information
          )
        return (
          <ItemContainer>
            <ItemTitle> {item.title} </ItemTitle>
            {item.links !== undefined && <LinksFooter data={item.links} />}
            {item.information !== undefined && (
              <ItemInfo>{itemInformation}</ItemInfo>
            )}
            {item.socialIcons !== undefined && (
              <IconsContact icons={item.socialIcons} />
            )}
          </ItemContainer>
        )
      })}
    </FooterWrapper>
  )
}

const FooterWrapper = styled.div`
  background-color: rgba(35, 29, 48, 0.05);
  width: 100%;
  height: 220px;
  margin-top: 16px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 50px 160px;
  align-items: start;
  flex-wrap: wrap;
  @media (min-width: 1900px) {
    position: ${props => props.elPosition};
    width: 100%;
    left: 0;
    bottom: 0;
    justify-content: center;
    z-index: 500;
  }
  @media ${device.laptopL} {
    padding: 20px 160px;
    height: auto;
    margin-top: -16px;
    position: ${props => props.elPosition};
    bottom: 0;
    z-index: 500;
  }
  @media (max-width: 1400px) {
    padding: ${props => props.paddingLaptop};
  }
  
  @media ${device.laptop} {
    padding: 32px 80px;
    height: auto;
    margin-top: -16px;
    flex-direction: row;
    position: relative;

    bottom: 0;
    z-index: 0;
  }
  @media ${device.tablet} {
    padding: 120px 32px;
    height: auto;
    margin-top: -16px;
    position: relative;
  }
  @media ${device.mobileL} {
    padding: 64px 16px;
    height: auto;
    margin-top: -16px;
  }
`

const ItemTitle = styled.h5`
  color: ${colors.dark};
  font-family: FreightSans;
  margin-top: 10px;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
`

const ItemContainer = styled.div`
  margin-right: 96px;
  max-width: 170px;
  width: 100%;
  :nth-of-type(2) {
    max-width: 150px;
  }
  :nth-of-type(3) {
    max-width: 180px;
  }
  @media (max-width: 1280px) {
    margin-right: 48px;
  }
  @media ${device.laptop} {
    margin-right: 24px;
  }
  @media ${device.mobileL} {
    margin-right: 32px;
    max-width: 150px;
    margin-bottom: 29px;
    :nth-of-type(2) {
      max-width: 120px;
    }
  }
`

const ItemInfo = styled.p`
  color: rgba(35, 29, 48, 0.6);
  font-family: FreightSans;
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 16px;
  @media ${device.mobileL} {
    font-size: 16px;
  }
`

const StyledLink = styled.a`
  color: rgba(35, 29, 48, 0.6);
  font-family: FreightSans;
  font-size: 18px;
  line-height: 24px;
  text-shadow: none;
  display: block;
  @media ${device.mobileL} {
    font-size: 16px;
  }
  :hover {
    color: #a62651;
  }
`

export default FooterContact
