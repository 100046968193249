import React, { useState } from "react"
import styled from "styled-components"
import { device, colors } from "../../../styles/constants"

/* Section Icons from page with map  */

interface IconsContactProps {}

const IconsContact: React.FunctionComponent<IconsContactProps> = ({
  icons,
}): React.ReactElement => {
  return (
    <SocialWrapper>
      {icons.map(icon => {
        let color = icon.colorIcon
        let black = icon.icon

        return (
          <a target="blank" href={icon.linkIcon}>
            <img
              src={icon.icon}
              onMouseEnter={e => (e.currentTarget.src = color)}
              onMouseLeave={e => (e.currentTarget.src = black)}
            />
          </a>
        )
      })}
    </SocialWrapper>
  )
}

const SocialWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  img {
    cursor: pointer;
  }
  @media ${device.mobileL} {
    margin-top: 19px;
  }
`

export default IconsContact
