import React from "react"

import styled, {keyframes} from "styled-components"
import FlipbaseIcons from "../DecorationElements/FlipbaseIcons"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import { Link } from 'gatsby'
import { SpringFadeUp, TrailFadeUp } from "../../utils/animation"
import ModalHeader from "../Layout/ModalHeader"

interface FlipbaseHeroProps {
  title: string,
  subhead: string,
  description: string,
  callToAction: string,
  linkTo: string,
  imageHero: string,
  backgroundImageHero: string,
  linkToAnchor: string,
  scheduleButton: boolean
}

const FlipbaseHero: React.FunctionComponent<FlipbaseHeroProps> = ({
  title, 
  subhead, 
  description, 
  imageHero, 
  backgroundImageHero,
  linkToAnchor,
  scheduleButton,
}): React.ReactElement => {
  
  return (
    <HeroWrapper>
        <Overlay></Overlay>
            <HeroContainer>
                <ContentWrapper>
                    <HeroContent>
                      <TrailFadeUp>
                        { imageHero && (
                          <HeroImage src={imageHero}/>
                        )}
                        { title && (
                            <TitleHero> {title} </TitleHero>
                        )}
                        { subhead && (
                            <SubHeadHero> {subhead} </SubHeadHero>
                        )}
                        { description && (
                            <DescriptionHero> {description} </DescriptionHero>
                        )}
                        { scheduleButton === true && (
                            <ModalHeader callToAction="Schedule Demo" />
                        )}
                      </TrailFadeUp>
                    </HeroContent>
                </ContentWrapper>
                  <AnchorLink to={linkToAnchor} title="Our team">
                    <IconWrapper>
                        <FlipbaseIcons 
                            type="arrov-down"
                            bgColor="#ffffff"
                        /> 
                    </IconWrapper>
                  </AnchorLink>
            </HeroContainer>
        <Background backgroundImageHero={backgroundImageHero}></Background>
    </HeroWrapper>
  )
}


const HeroWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
`

const Overlay = styled.div`
  position: absolute;
  height: 100vh;
  width: 100%;
  top: 0;
  left: 0;
  background: rgba(0,0,0,0.75);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 600;
`

const Background = styled.div`
  position: absolute;
  height: 100vh;
  width: 100%;
  top: 0;
  left: 0;
  background-attachment: scroll;
  background-position: center bottom;
  background-image: ${props => `url(${props.backgroundImageHero})`};
  background-size:100%;
`

const ContentWrapper = styled.div`
  height: 100vh;
  flex-direction:row;
  align-items:center;
  justify-content:center;
  display: flex;
  width:100%;
  max-width: 1200px;
  margin:0 auto;
  
`
const HeroContainer = styled.div`
  text-align:center;
  position:relative;
  z-index:999;
`

const HeroContent = styled.div`
  padding: 0 0 42px;
  text-align:center;
`

const TitleHero = styled.h3` 
  font-family: FreightSans;
  font-weight: 600;
  color: #ffffff;
  padding: 24px 0 24px 0;
  letter-spacing: .5px;
  font-size: 72px;
  line-height: 1.17;
  margin-bottom:0;
`

const SubHeadHero = styled.span`
  font-size: 24px;
  font-family: FreightSans;
  font-weight: 600;
  color: #ffffff;
`
const bounce = keyframes`
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(7px);
  }
`

const IconWrapper = styled.div`
  position: absolute;
  z-index: 9;
  bottom: 40px;
  right: 0;
  left: 0;
  text-align: center;
  animation: 1.4s ${bounce} ease-out infinite;
  cursor:pointer;
  svg:hover {
    opacity:0.6;
  }
`

const HeroImage = styled.img`
  margin:0 auto 32px !important;
  min-width:240px;
`
const DescriptionHero = styled.p`
    font-size: 18px;
    font-family: FreightSans;
    color: #ffffff;
    margin:32px auto;
    font-weight:400;
    max-width:600px;
`

export default FlipbaseHero